.react-confirm-alert-overlay {
  /* Display on top of bootstrap modals which have a z-index of 1050 */
  z-index:1051 !important;
}
.confirm-alert {
  /* Color is a slightly darkened version of bootstrap's light color */
  background-color:var(--primary);
  padding:40px 60px;
  text-align:center;
  color:var(--light);
  box-shadow:0 20px 75px rgba(0, 0, 0, 0.23);
}
.confirm-alert > button {
  margin:10px;
  margin-bottom:0;
  padding:5px;
  width:120px;
  border-color:var(--light);
  color:var(--light);
}
.confirm-alert > button:hover {
  color:var(--primary);
  background-color:var(--light);
}
.confirm-alert > h3 {
  margin-bottom:1rem;
  font-size:1.5rem;
}
.confirm-alert > p {
  font-size:.9rem;
  /* Bootstrap secondary color theme */
  /* color:var(--secondary); */
}
