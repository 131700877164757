.HistoryViewer-inactive {
  position: absolute;
  right: 33px;
  font-size: 18px;
  margin-top: 10px;
  color: black;
  z-index: 3 !important;
}
.history-viewer-dialog {
  height: 75%;
  max-width: 80%;
}
