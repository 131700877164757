.Legend {
  width: 100%;
  background-color:rgb(230,230,230);
}
.Legend[data-closed="true"] {
  padding:5px;
  display:flex;
  align-items:center;
  justify-content:flex-end;
}

.legendHeader {
  flex-grow:1;
  margin:0;
  font-size:16px;
}

/* Default = flexbox, but this prevents the ButtonGroup from being multiple lines, which is the default behavior. */
.btn-group {
  display:block;
}

.TypeButton {
  border-radius:20px !important;
  font-size:13.5px;
  border-color:rgb(100,100,100);
  margin:7.5px;
  color:black !important;
  /* Make buttons smaller */
  padding:2px 6px 2px 6px;
}

.TypeButton:hover, .TypeButton.active, .TypeButton:hover.active {
  border-color:rgb(100,100,100);
  /* color:rgb(80,80,80) !important; */
}

.TypeButton:hover {
  background-color: var(--hover-background-color) !important;
}

.TypeButton.focus, .TypeButton:focus {
  box-shadow:none;
}

/* Setting --highlight-box-shadow-color in the inline style property, which can later be accessed and used when focused */
.TypeButton.active {
  box-shadow: 0 0 0 0.12rem var(--highlight-box-shadow-color) !important;
  border-color:var(--highlight-box-shadow-color) !important;
}

/* Since the default behavior of a ButtonGroup is one line, the buttons contained must be stripped of the style that ends up looking strange on multiple lines */
.TypeButton:first-child {
  margin-left:0;
  border-radius:0;
}
.TypeButton:last-child {
  margin-right:0;
  border-radius:0;
}

.legend-vis-control {
  /* Using margin for consistency */
  margin-top:7px;
  margin-right:5px;
  font-size:24px;
  right:0px;
  position:absolute;
  /* Since the icon is overlayed over the force graph when collapsed, set z-index to higher than force graph's (auto) */
  z-index: 1 !important;
}
.legend-vis-control-open {
  font-size:24px;
}
.graph-element-type-container {
  padding:5px 5px 5px 7.5px;
}

.graph-element-type-container {
  border-top:2px solid rgb(200,200,200);
}
.graph-element-type-container:not(:last-child) {
  padding-bottom:7.5px;
}

.graph-element-header {
  margin-bottom:0px;
}

.graph-element-content {

}
