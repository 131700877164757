.TableViewerContainer {
  /* Something to do with the way position/overflow/padding works -- this padding has to be on a container or it does not display properly */
  padding: 15px;
}
.TableViewer {
  position:relative;
  width:100%;
  height:100%;
  max-height:100%;
  display:flex;
  flex-direction:column;
}
.TableViewer > .tab-content {
  flex:1;
  overflow-y:auto;
}
.TableViewer .rt-td {
  white-space:pre !important;
}
.TableViewer > .nav-tabs {
  position:relative;
}
.TableViewer > .nav-tabs, .TableViewer > .nav-tabs > .nav-item {
  border-bottom:0;
}
.table-viewer-button-container {
  position:absolute;
  top:0;
  right:0;
  z-index:1;
  display:flex;
  align-items:center;
}
.table-viewer-button-container > * {
  white-space:nowrap;
}
.table-viewer-button-container > *:not(:last-child) {
  margin-right:5px;
}
.table-filter-container {
  /* display:flex; */
  /* flex-wrap:wrap; */
  /* margin:5px; */
}
.table-filter-container > * {
  float:left;
  width:25%;
  word-break:break-all;
  padding:5px;
  margin:0;
}
