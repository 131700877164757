:root {
  --background-hover-transition-time: .85s;
  --background-active-transition-time: .35s;
}
.App {
    /*text-align: center;*/
     position : absolute;
     height : 100%;
     width : 100%;
     background: black;
}
body {
  overflow : auto !important;

}
.divider {
     width: 100%;
     border: 1px solid lightgray;
}
#headerContainer {
    width:100%;
    display:flex;
    align-items:center;
    padding: 6.5px;
}
#headerContainer > * {
  margin:0 auto;
}
/* Add additional 5 pixels of margin to the loading icon when it is visible */
#headerContainer > p ~ div {
  margin-right: 10px;
}
#headerContainer > p {
}
#splitpane {
    position: relative;
    height : 80%;
    width : 100%;
}
#graph {
    position: relative;
    width : 100%;
    height : 100%;
}
#browser {
    position: relative;
    height : 100%;
    width : 100%;
}
#viewContainer {
    display:flex;
    height:100%;
    width:100%;
    position:absolute;
}
#viewContainer > .SplitPane {
    flex:1 !important;
}
#graphContainer[data-vis-mode='VR'], #graphContainer[data-vis-mode='VR'] > div, #graphContainer[data-vis-mode='VR'] > div > div {
  height:100%;
}
#bottomContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height:100%;
  background-color: transparent;
  display:flex;
  align-items:flex-start;
  pointer-events:none; /* So that the transparent container does not consume all the pointer events meant for the force graph */
}
#bottomContainer > * {
  pointer-events:initial; /* So that the container's children can still receive pointer events */
}
#toolbar {
  flex:0 0 auto;
  border-color:rgba(70,70,70,1);
  background-color:rgba(45,45,45, 1);
  border-bottom-width:5px;
}
.Tool {
}
.Tool[data-active-tool="true"] {
  background-color:rgb(15,15,15);
  /* background-color:rgb(220,220,220); */
}
.Tool[data-active-tool="true"] > * {
  /* color:black; */
}
#graphOverlayContainer {
  position:relative;
  flex-grow:1;
  height:100%;
  display:flex;
  align-items:flex-start;
  pointer-events:none;
}
#graphOverlayVerticalContainer {
  flex-grow:1;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
}
#schemaBanner {
    /* background-color: rgb(30, 30, 30); */
    /* border-bottom: 4px solid rgb(50, 50, 50); */
    width: 100%;
    background-color: transparent;
    padding: 10px;
    /* text-align: center; */
    /* text-decoration: underline; */
    font-weight: bold;
    font-style: italic;
    font-size: 18px;
    color: rgb(235, 235, 235);
    display:flex;
    align-items: center;
    pointer-events:none;
}
.LinkExaminer {
  pointer-events:initial;
  margin-left: 10px;
  max-height:400px;
}
.FindTool {
  position: absolute;
  /* width: calc(100% - 16px); */
  width: 400px;
  margin: 8px;
  /* margin-left:0; */
  /* 16px for margin */
  max-height:calc(100% - 16px);
  /* position:absolute;
  right:5px;
  top:5px; */
}
.find-tool-tabs {
  border-bottom:1px solid #dee2e6;
}
.tab-content .tab-content {
  margin-top:25px;
}
.fa-spin {
  /* Pseudo fa-spin class because react-icons doesn't seem to natively support it */
  -ms-animation:spin 2s linear infinite;
  -o-animation:spin 2s linear infinite;
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
#schemaViewToggleButtonContainer {
  flex-grow: 1;
}
#schemaViewToggleButton {
  float: right;
  background-color: black;
  color: #007bff;
  pointer-events:initial;
}
.tableViewPane {
  overflow-y:auto;
  /* padding:15px; */
}
#info {
    position: relative;
    background-color: rgb(39, 40, 34);
    /* We want it to always be at least the same size as the split pane, but we want it to overflow if the JSONTree component is much larger */
    min-height:100%;
    /* bottom: 0px; */
    /* overflow:auto; */
    border-left: 2px solid rgb(166, 226, 46);
}
#info > ul {
    padding:0 1em .5em .5em !important;
    margin:0 !important;
    /* margin:5px !important; */
}
.react-json-view {
    height:100%;
    position: absolute;
    overflow: auto;
 }
.object-viewer-header {
  position:relative;
  width:100%;
  padding:.5em;
  padding-bottom:.25em;
  text-align:center;
  /* border-bottom:2px solid rgb(166, 226, 46); */
 }
.object-viewer-header > h6 {
  color:rgb(166, 226, 46);
  margin-bottom:0;
}
.object-viewer-close-button {
  position:absolute;
  /* Margin is for the 10px padding */
  margin:.5em;
  top:0;
  right:0;
  color:rgb(166, 226, 46);
  font-size:18px;
}
.object-viewer-close-button:hover {
  color:rgb(147, 199, 42);
}
.object-viewer-close-button:active {
  color:rgb(116, 156, 36);
}
.type-chart {
  width: 95%;
  height: 100%;
  max-height: none;
  max-width: none;
}
#typeChartTitle {
  display:flex;
  align-items:center;
}
#swapBar {
  margin-top:2px;
  margin-left:12.5px;
  font-size:24px;
}
.Resizer {
  border-width:0;
  background: transparent;
}
.Resizer.disabled {
  cursor:auto !important;
}

.tableView {
    position : absolute;
    bottom : 0px;
    width : 100%;
    height : 200px;
    border-top: 2px solid lightblue;
 }
.App-logo {
    /*animation: App-logo-spin infinite 20s linear; */
     height: 7vmin;
     pointer-events: none;
    text-align : left;
    position : absolute;
    left : 20px;
    top : 15px;
 }

.App-header {
    background-color: #282c34;
     /* min-height: 8vh; */
     display: flex;
     align-items: center; /* center; */
     justify-content: left; /*center;*/
     color: white;
     font-size: 28px;
     font-style: italic;
     font-weight : bold;
 }
.App-control {
    float : right;
    margin-left : 5px;
    margin-right : 5px;
}
.App-control-toolbar {
  color: rgba(220,220,220,.9);
}
.App-control-toolbar[data-active="true"] {
  color: rgba(160,160,160,.9);
}
.App-control-toolbar.ionic {
  font-size: 22px;
}
.App-control-toolbar.fa {
  font-size: 18px;
}
.answerNavigator {
    width: 95%;
    height: 95%;
    max-width: none!important;
    max-height: 100%;
    height: 100%;
}
.modal-content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    /* max-height: 90%; */
    height: 100%;
}
.messageDialog .tab-content {
  margin-top:10px;
}
.messageDialog .tab-pane {
  /* Retain the formatting from the message */
  white-space:pre-wrap;
}
.import-export-modal {
  display:flex !important;
  justify-content:center;
  align-items:center;
}
.import-export-modal-dialog {
  /* height:400px; */
  /* width:500px; */
  max-width:none;
}
.import-export-modal-dialog .modal-body {
  display:flex;
}
.import-export-modal-dialog .modal-body .no-select {
  /* flex:1; */
  background-color:rgb(245,245,245);
  border-radius:5px;
  border:1.5px solid rgb(230,230,230);
  height:auto;
  display:flex;
  flex-direction:column;
  min-height:200px;
}
.import-export-modal-dialog .modal-body .row > * {
  margin:0 5px;
}
.import-export-modal-dialog .modal-body .no-select > * {
  padding:10px;
}
.import-export-modal-dialog .modal-body .no-select > *:not(:last-child) {
  padding-bottom:0;
}
.import-export-modal-dialog .copy-url-container {
  flex-grow: 1;
  margin-top: 10px !important;
}
.import-export-modal-dialog .copy-url-container input {
  background-color: initial;
}
.import-export-icon-container {
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.import-export-icon-container > * {
  margin-left:7.5px;
  margin-right:7.5px;
}
.import-export-icon-container > svg {
  /* float:left; */
  font-size:28px;
}
.import-export-icon-container > span {
  flex-grow:1;
  font-weight:600;
  font-size:16px;
  display:block;
  text-align:center;
  padding-bottom:10px;
}
.import-options-container, .export-options-container {
  padding:15px !important;
  padding-bottom:0 !important;
  /* 2.5px from horizontal-bar */
  /* padding-top:calc(15px + 2.5px) !important; */
  flex-grow:1;
  display:flex;
  flex-direction:column;
}
.import-options-container > form > div, .export-options-container > form > div {
  /* display:flex; */
  /* align-items:center; */
}
/* .import-options-container > form > div > label, .export-options-container > form > div > label {
  margin-left:0.5rem;
} */
.import-options-container > form, .export-options-container > form {
  flex-grow:1;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
}
.import-options-container > form > *, .export-options-container > form > * {
  margin-bottom:15px;
}
.import-options-container > *:not(:last-child), .export-options-container > *:not(:last-child) {
  margin-bottom:15px;
}
.import-export-icon-container.horizontal-bar::after {
  width:95%;
  left:2.5%;
  top:calc(100% + 2.5px);
  background-color:transparent;
  height:2px;
}
.export-options-container > form > .form-group.form-inline > *:first-child {
  margin-right:.3125rem;
}
.help-modal {
  /* height:60%; */
}
.toolbar-help-modal {
  display:flex !important;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
}
.toolbar-help-modal-dialog {
  height:75%;
  margin:0;
  width:700px;
  max-width:none;
}
.toolbar-help-modal-dialog .modal-body {
  display:flex;
  flex-direction:column;
}
.toolbar-help-modal-dialog .modal-body > .tab-content {
  height:0; /* ?? */
  flex-grow:1; /* ?? the height:0 is required to fix overflow for some reason */
  margin-top:10px;
  display:flex;
}
.toolbar-help-tabs {
  /* flex-grow:1; */
  /* display:flex; */
  /* flex-direction:column; */
  /* height:100%; */
}
.toolbar-help-tab-panel {
  flex-grow:1;
}
.toolbar-help-tab-panel.active {
  display:flex !important;
  flex-grow:1;
}
/* .toolbar-help-tab-panel:not(.react-tabs__tab-panel--selected) {
  display:none;
} */
.toolbar-help-content {
  flex-grow:1;
  margin-left:10px;
}
.toolbar-help-content-body {
  height:100%;
  padding:0;
  display:flex;
  flex-direction:column;
}
.toolbar-help-content-body > .card-text {
  overflow-y:auto;
  padding:.75rem 1.25rem;
}
.toolbar-help-tool-group {
  flex:0 0 12%;
  height:100%;
  overflow-y:auto;
}
.toolbar-help-tool-group > * {
  /* flex:0; */
}
.toolbar-help-tool-group > *:last-child {
  /* No padding allows it to essentially go invisible, meaning that it will not show up if there is any overflow */
  padding:0;
  flex-grow:1 !important;
}
.toolbar-help-tool-button {
  padding-left:0;
  padding-right:0;
  flex-grow:0;
  /* width:33.33%; */
  /* flex: 1 1 0; */
  /* padding-top:0; */
  /* padding-bottom:0; */
  display:flex;
  justify-content:center;
  align-items:center;
  word-break:break-word;
  /* white-space:nowrap; */
  text-align:center;
}
.toolbar-help-tool-button > * {
  padding:0;
}
/* First child is the header of the section */
.section > *:not(:first-child) {
  padding-left:15PX;
}
.section dt {
  /* Tone down the font weight applied to description lists a bit */
  font-weight:500;
}
#helpGrid > * {
  padding-left:7.5px;
  padding-right:7.5px;
}
#helpGrid > *:first-child {
  padding-top:7.5px;
}
#helpGrid > *:last-child {
  padding-bottom:7.5px;
}
#helpGrid > * > * {
  padding:7.5px;
}
#helpGrid > * > * > * {
  height:100%;
}
#spinner {
    position : absolute;
    right : 250px;
    top : 20px;
}
#clearCache {
    float: right;
}
#navModeButton {
}
#runButton {
  height:60%;
    /* position: absolute; */
    /* right: 41px; */
 }
.settingsDialog {
    width: 95%;
    max-width: none!important;
    height: 95%;
    /*max-height: 95%; */
}
.checkbox-container > *:first-child {
  margin-top:5px;
}
#appControlContainer {
  display:flex;
  flex-direction:column;
}
#appControlContainer > * {
  margin:0;
}
#settings {
    /* position: absolute; */
    /* right : 3px; */
    /* top : 11px; */
}
#answerViewer {
  /* position: absolute; */
  /* right : 3px; */
  /* top: 40px; */
}
.Legend {
  border-bottom:1px solid rgb(200,200,200);
}
.Legend[data-closed="true"] {
  border-top:1px solid rgb(200,200,200);
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.splitPane {
    position: relative;
 }
.query-code .CodeMirror-scroll {
    height: 40%;
 }
.query-code .CodeMirror {
    text-align: left!important;
    padding: 2px;
    font-size : 14px;
    font-weight : bold;
    width: 100%;
    height: 50%!important;
    border-color: lightgray 1px solid;
 }
.editor {
  background-color:white;
 }
.editor-vis-control.legend-vis-control {
  z-index:3 !important;
}
.rv-force {
  border: 1px solid gray;
}
.title-edit {
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
  color:#0088cc;
  border-bottom:1px dashed #0088cc;
}
#cachedQueriesModal .query-title {
  min-width:0; /* Need it because flex box changes min-width to auto */
}
#cachedQueriesModal .query-title > input {
  flex-grow:1;
}
/* Elevate it by 1 pixel since the first has a border */
#cachedQueriesModal .query-title > span + span {
  margin-bottom: 1px;
}
/* When the inline editing is active hide the adjacent span because we want 100% width (displays if the query has been edited or not) */
#cachedQueriesModal .query-title > input + span {
  display:none;
}
/* ??? */
.Resizer {
    background: #000;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

 .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

 .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.testing {
  transform:translateX(15px);
}
.autocomplete-result-error {
  background-color:var(--danger) !important;
}
.loading-animation::after {
  content: '';
  -ms-animation:loading-text 2.5s linear infinite;
  -o-animation:loading-text 2.5s linear infinite;
  -webkit-animation:loading-text 2.5s linear infinite;
  -moz-animation:loading-text 2.5s linear infinite;
  animation:loading-text 2.5s linear infinite;
}
.no-select {
  user-select:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  -ms-user-select:none;
}
.horizontal-bar {
  position:relative;
  padding-bottom:2.5px;
}
.horizontal-bar::after {
  content:'';
  position:absolute;
  bottom:-1.5px;
  left: 0;
  width:100%;
  height:1.5px;
  background-color:rgba(240,240,240,1);
}
.vertical-bar {
  position:relative;
}
.vertical-bar::before {
  content:'';
  position:absolute;
  width:1.25px;
  height:100%;
  left:-1.25px;
  background-color:rgb(215,215,215);
}

/* Embedded style rules */
.App.embedded .react-codemirror2 {
  border: 2px solid #f0f0f0;
}
.App.embedded .CodeMirror {
  padding: 0;
  height: 100% !important;
}
.App.embedded .CodeMirror-scroll {
  height: 100% !important;
  max-height: 30vh;
}

/* Gamma Viewer rules */
body.modal-open > *[role="tooltip"] {
  /* This is attached to the MuiPaper popup/tooltips used in the answer viewer for filtering.
   * Since the Gamma Viewer can appear in a Bootstrap modal, and these popups are positioned absolutely relative to the root,
   * we need to bump up the z-index to ensure they appear above the modal.
   */
  z-index: 2000 !important;
}

@-ms-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-o-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes loading-text {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}
