body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.queries-modal {
  display:flex !important;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
}
.queries-modal-dialog {
  height:75%;
  margin:0;
  width:650px;
  max-height:75%;
  max-width:none;
}
.queries-modal-dialog > .modal-content {
  height:100%;
}
.queries-modal-dialog > .modal-content > .modal-body {
  display:flex;
  overflow-y:auto;
}
.container {
  height:100%;
}
.right-container {
  position:relative;
  display:flex;
  flex-direction:column;
  height:100%;
  /* max-height:100%; */
  /* max-width:75%; */
  /* width:100%; */
  /* flex:0; */
}
.query-list {
  float:left;
  margin-right:16px;
  overflow-y:auto;
  /**
   * Grows to a maximum of 22.5% but may shrink if the largest title is fairly short.
   */
  max-width:22.5%;
  max-height:100%;
}
/**
To extend empty last child for entire height
.query-list > *:nth-last-child(2) {
  border-bottom-width:0.5px;
}
.query-list > *:last-child {
  border-width:0.5px;
  flex-grow:1;
  padding:0;
}
*/
.query-list > button {
  /* Make sure that a really long word won't result in a scroll bar due to overflow */
  word-wrap:break-word;
  text-align:center;
}
.query-title-container {
  display:flex;
  /* justify-content:flex-end; */

  align-items:center;
  /* margin-bottom:14px; */
  /* margin:0px 5px 0 0; */
  padding:7.5px 7.5px;
  background-color:rgb(247,247,247);
  border:1px solid #ddd;
  border-bottom:none;

  /* position:absolute; */
  /* z-index:5000; */
  /* right:25px; */
  /* top:6px; */
}
.query-title {
  margin-right:7.5px;
  flex-grow:1;
  font-size:14px;
  display:flex;
  align-items:center;
  /* font-weight:600; */
}
.query-button-container {
  display:flex;
  align-items:center;
}
.query-button-container > * {
  font-size:14px;
}
.query-button-container > *:not(:last-child) {
  margin-right:8px;
}
.query-modal-code {
  /* flex-grow:1; */
  height:100%;
  /* max-height:100%; */
  display:flex;
  overflow:hidden;
  /* margin-bottom:16px; */
}
.query-modal-code .CodeMirror {
  /* height:100% !important; */
  font-size:13px;
  border:1px solid #ddd;
  flex-grow:1;
  /* overflow:hidden; */
  height:auto;
  width:100%;
  /* max-height:100%; */
}
.query-modal-code .CodeMirror-vscrollbar {
  /* Equivalent of overflow: scroll */
  display:block !important;
}
.query-modal-code .CodeMirror-wrap {
  height:100%;
}
.query-modal-code .CodeMirror-scroll {
  width:100%;
}
.query-modal-code .CodeMirror-lines {
  display:flex;
}

.HistoryViewer-inactive {
  position: absolute;
  right: 33px;
  font-size: 18px;
  margin-top: 10px;
  color: black;
  z-index: 3 !important;
}
.history-viewer-dialog {
  height: 75%;
  max-width: 80%;
}

.BrowseNodeInterface {
  position:absolute;
  /* Appear above the find tool */
  z-index:1;
  top:10px;
  right:10px;
  /* Sadly, this results in the typeahead overflowing and it's virtually unusable. */
  /* max-height:calc(100% - 20px); */
  /* overflow-y:auto; */
  border:1px solid rgb(200,200,200);
  pointer-events:initial;
  min-width:325px;
  background-color:white;
}
.BrowseNodeInterface > * > * {
  padding:10px;
}
.browse-node-header {
  text-align:center;
  display:flex;
  align-items:center;
  border-bottom:1px solid rgb(200,200,200);
  background-color:rgb(245,245,245);
}
.browse-node-header > h6 {
  margin:0;
  font-size:17px;
  margin-right:6px;
  flex:1 1;
}
.browse-node-header > svg {
  padding:0;
  position:absolute;
  right:10px;
}
.browse-node-body > div:not(.button-container) {
  padding:10px;
  padding-bottom:0;
}
.browse-node-body > div:not(.button-container) > *:last-child {
  margin-top:5px;
}
.browse-node-body > .button-container {
  margin-top:15px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-top:1px solid rgb(220,220,220);
  background-color:rgb(240,240,240);
}
.browse-node-body > .button-container > * {
  font-size:14px;
}
.browse-node-body .dropdown-menu {
  /* Sadly, a bug with Chrome 75 (and almost certainly other versions) causes text to be blurry when this element has a border radius. */
  border-radius:0 !important;
  border-radius:initial !important;
}

.Legend {
  width: 100%;
  background-color:rgb(230,230,230);
}
.Legend[data-closed="true"] {
  padding:5px;
  display:flex;
  align-items:center;
  justify-content:flex-end;
}

.legendHeader {
  flex-grow:1;
  margin:0;
  font-size:16px;
}

/* Default = flexbox, but this prevents the ButtonGroup from being multiple lines, which is the default behavior. */
.btn-group {
  display:block;
}

.TypeButton {
  border-radius:20px !important;
  font-size:13.5px;
  border-color:rgb(100,100,100);
  margin:7.5px;
  color:black !important;
  /* Make buttons smaller */
  padding:2px 6px 2px 6px;
}

.TypeButton:hover, .TypeButton.active, .TypeButton:hover.active {
  border-color:rgb(100,100,100);
  /* color:rgb(80,80,80) !important; */
}

.TypeButton:hover {
  background-color: var(--hover-background-color) !important;
}

.TypeButton.focus, .TypeButton:focus {
  box-shadow:none;
}

/* Setting --highlight-box-shadow-color in the inline style property, which can later be accessed and used when focused */
.TypeButton.active {
  box-shadow: 0 0 0 0.12rem var(--highlight-box-shadow-color) !important;
  border-color:var(--highlight-box-shadow-color) !important;
}

/* Since the default behavior of a ButtonGroup is one line, the buttons contained must be stripped of the style that ends up looking strange on multiple lines */
.TypeButton:first-child {
  margin-left:0;
  border-radius:0;
}
.TypeButton:last-child {
  margin-right:0;
  border-radius:0;
}

.legend-vis-control {
  /* Using margin for consistency */
  margin-top:7px;
  margin-right:5px;
  font-size:24px;
  right:0px;
  position:absolute;
  /* Since the icon is overlayed over the force graph when collapsed, set z-index to higher than force graph's (auto) */
  z-index: 1 !important;
}
.legend-vis-control-open {
  font-size:24px;
}
.graph-element-type-container {
  padding:5px 5px 5px 7.5px;
}

.graph-element-type-container {
  border-top:2px solid rgb(200,200,200);
}
.graph-element-type-container:not(:last-child) {
  padding-bottom:7.5px;
}

.graph-element-header {
  margin-bottom:0px;
}

.graph-element-content {

}

.TableViewerContainer {
  /* Something to do with the way position/overflow/padding works -- this padding has to be on a container or it does not display properly */
  padding: 15px;
}
.TableViewer {
  position:relative;
  width:100%;
  height:100%;
  max-height:100%;
  display:flex;
  flex-direction:column;
}
.TableViewer > .tab-content {
  flex:1 1;
  overflow-y:auto;
}
.TableViewer .rt-td {
  white-space:pre !important;
}
.TableViewer > .nav-tabs {
  position:relative;
}
.TableViewer > .nav-tabs, .TableViewer > .nav-tabs > .nav-item {
  border-bottom:0;
}
.table-viewer-button-container {
  position:absolute;
  top:0;
  right:0;
  z-index:1;
  display:flex;
  align-items:center;
}
.table-viewer-button-container > * {
  white-space:nowrap;
}
.table-viewer-button-container > *:not(:last-child) {
  margin-right:5px;
}
.table-filter-container {
  /* display:flex; */
  /* flex-wrap:wrap; */
  /* margin:5px; */
}
.table-filter-container > * {
  float:left;
  width:25%;
  word-break:break-all;
  padding:5px;
  margin:0;
}

.FileLoader {
  display:flex;
  flex-direction:column;
  align-items:center;
}
.FileLoader > *:first-child {
  margin-bottom:15px;
}
.FileLoader > button {
  width:100%;
}
.FileLoader > .filepond--wrapper {
  border:1.5px dashed rgb(210,210,210);
}
.FileLoader > .filepond--wrapper .filepond--root {
  position:relative;
  contain:initial;
  /* width:fit-content; */
}
.FileLoader > .filepond--wrapper .filepond--drop-label {
  /* width:fit-content; */
  position:relative;
  z-index:7;
}
.FileLoader > .filepond--wrapper .filepond--panel-root {
  display:none;
  position:relative;
  top:-100%;
}
.FileLoader > .filepond--wrapper .filepond--browser {
  /* position:relative; */
  /* top:-100%; */
}
.FileLoader > .filepond--wrapper .filepond--list-scroller {
  position:relative;
  /* width:auto; */
  margin:0;
  height:100%;
  -webkit-transform:none !important;
          transform:none !important;
  -webkit-transform:initial !important;
          transform:initial !important;
  top:calc(-100% + 1px);
  display:flex;
  justify-content:center;
  align-items:center;
}
.FileLoader > .filepond--wrapper .filepond--list {
  position:relative;
  left:0;
  /* width:fit-content; */
}
.FileLoader > .filepond--wrapper .filepond--item {
  position:relative;
}
.import-upload-area {
  box-sizing:content-box;
  margin:0 !important;
}

.react-confirm-alert-overlay {
  /* Display on top of bootstrap modals which have a z-index of 1050 */
  z-index:1051 !important;
}
.confirm-alert {
  /* Color is a slightly darkened version of bootstrap's light color */
  background-color:var(--primary);
  padding:40px 60px;
  text-align:center;
  color:var(--light);
  box-shadow:0 20px 75px rgba(0, 0, 0, 0.23);
}
.confirm-alert > button {
  margin:10px;
  margin-bottom:0;
  padding:5px;
  width:120px;
  border-color:var(--light);
  color:var(--light);
}
.confirm-alert > button:hover {
  color:var(--primary);
  background-color:var(--light);
}
.confirm-alert > h3 {
  margin-bottom:1rem;
  font-size:1.5rem;
}
.confirm-alert > p {
  font-size:.9rem;
  /* Bootstrap secondary color theme */
  /* color:var(--secondary); */
}

.Toolbar {
  height:auto;
  width:auto;
  border:0px solid rgb(50,50,50);
  border-right-width:5px;
  display:flex;
  flex-direction:column;
  resize:vertical;
  overflow:auto;
  height:100%;
  max-height:100%;
}

.Tool {
  position:relative;
  /* white-space:nowrap; */
  padding:5px;
  border-radius:3px;
  display:flex; /* Inline-block doesn't seem to work to fit to contents when icon is contained, but flex does */
}

.Tool:active {
  background-color:rgba(30,30,30,1);
}

.Tool:active > * {
  color:rgba(180,180,180,.9);
}

.Tool:hover {
  background-color:rgba(35,35,35,1);

}

.Tool[data-active-tool="true"] {
  background-color:rgba(0,0,0,1);
}

/* Lots of styling to create a triangle in the bottom-right corner */
.tool-group > .Tool::after {
  content:'';
  position:absolute;
  bottom:0;
  right:0;
  -webkit-transform:translate(-1px,-1px);
          transform:translate(-1px,-1px); /* Padding/margin does not work */
  border-radius: 0;
  border-color:transparent;
  border-style:solid;
  border-width:2px;
  border-right-color:rgba(220,220,220,.9);
  border-bottom-color:rgba(220,220,220,.9);
}

/* Change color of corner when active to match background */
.Tool[data-tool-group="true"]:active::after {
  border-right-color:rgba(130,130,130,.9);
  border-bottom-color:rgba(130,130,130,.9);
}

.select-menu {
  position:fixed;
  z-index:1;
  background-color:rgb(30,30,30);
  /* top:0; */
  /* left:100%; */
  margin-left:3.5px;
  border:1px solid rgba(150,150,150,1);
  display:flex;
  flex-direction:column;
}


.select-menu-tool {
  white-space:nowrap;
  display:inline-block;
  width:100%;
  font-size:14px;
  color:rgba(220,220,220,.9);
  padding:5px;
  user-select:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  -ms-user-select:none;
}

.select-menu-tool[data-active-tool="true"] {
}

.select-menu-tool:hover {
  background-color:rgba(50,50,50,1);
}

.select-menu-tool:active {
  background-color:rgba(45,45,45,1);
}

.select-menu-tool > * {
  font-size:16px;
  color:rgba(220,220,220,.9);
}

.Tool > * {
  /* I'm not sure this will support all of the icon toolkits in react-icons (only tested on FontAwesome and IonIcons) */
  font-size:16px;
  color:rgba(220,220,220,.9); /* Easier on the eyes and just generally looks better */
}

.toolbar-header {
}

.toolbar-content {
  flex-grow:1;
  padding:6px;
  display:grid;
  grid-template-columns:auto auto;
  grid-auto-rows:-webkit-min-content;
  grid-auto-rows:min-content;
}

.toolbar-button-container {
  position:relative;
  flex-grow:0;
}
.toolbar-button-container::after {
  content:'';
  position:absolute;
  bottom:0;
  left: 5%;
  width:90%;
  height:2px;
  background-color:rgba(70,70,70,1);
  /* border-bottom:2px solid rgba(50,50,50,1); */
}

.toolbar-button-container > .tool-container {
  margin:6px;
}

.tool-container {
  /* Type of container that each tool and tool group is wrapped inside of */
  margin:3px;
  display:flex;
  justify-content:center;
}

.LinkExaminer {
  background-color:rgba(0,0,0,.4); /* replace with rgb(20,20,30) to remove alpha layer */
  color:rgba(220,220,220,1);
  overflow-y:auto;
  border: 2px solid rgba(125,125,125,1);
}
.link-examiner-header {
  /* border-bottom: 1.5px solid rgba(240,240,240,1); */
  display:flex;
  position:relative;
  justify-content:space-between;
  align-items:center;
  padding-bottom:2.5px;
  margin:2.5px 5px;
}
.link-examiner-header-text {
  display:inline-block;
  padding-bottom:2.5px;
  font-size:16px;
  font-weight:600;
}
.link-examiner-close-button {
  margin-left:20px;
}
.link-examiner-close-button:hover {
  color:rgb(200,200,200);
}
.link-examiner-close-button:active {
  color:rgb(175,175,175);
}
.link-examiner-header-info {
  font-size:12px;
  margin: 7.5px 0 7.5px 0;
  font-weight:bold;
  position:relative;
}
.header-node {
  margin:5px;
}
.header-node > *:first-child {
  margin-right:5px;
}
.link-label {
  font-size:12px;
  padding:1px;
  margin:5px;
}
.link-label > * {
  margin:2px;
}
.link-label-text {
  cursor:pointer;
  /* color:#2b9cff; */
}

.FindTool {
  pointer-events:initial;
  background-color:white;
  display:flex;
  flex-direction:column;
  border:2px solid rgb(215,215,215);
  max-height:calc(100% - 10px);
  /* max-width:30%; */
  min-width:150px;
  /* padding: 3px 0; */
}
.find-tool-input {
  background-color:inherit;
  padding:5px;
  border:none;
  outline:none;
  font-size:.8rem;
  /* flex:1; */
  word-break:break-all;
  /* max-height:400px; */
  /* overflow:auto; */
  width:100%;
  border-radius:0;
}
.find-tool-result-container {
  resize:vertical;
  max-height: -webkit-min-content;
  max-height: -moz-min-content;
  max-height: min-content;
  overflow-y:auto;
  border-top:2px solid rgb(220, 220, 220);
  display: flex;
  flex-direction: column;
}
.find-tool-result {
  padding:5px 7.5px;
  display:flex;
  justify-content:center;
  align-items:center;
  text-align:center;
  word-break: break-word;
  flex-grow: 1;

  -webkit-transition:background-color .85s;

  transition:background-color .85s;

  user-select:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  -ms-user-select:none;
}
.find-tool-result:not(:last-child) {
  border-bottom:2px solid rgb(220, 220, 220);
}
.find-tool-result-header {
  font-weight: 500;
}
.find-tool-result:hover {
  background-color:rgb(235, 235, 235);
}
.find-tool-result-header:hover {
  background-color:inherit;
}

/* Non-input placeholder rule - due to the modern css pseudo classes this probably does not work in a lot of older browsers */
*:not(input):empty[contenteditable="true"][placeholder]::before {
  /* Pseudo-placeholder attribute on content editable elements */
  content:attr(placeholder);
  color:gray;
  pointer-events: none;
}

.CodeMirror-hover-tooltip {
	background-color: infobackground;
	border: 1px solid black;
	border-radius: 4px 4px 4px 4px;
	color: infotext;
	font-family: monospace;
	font-size: 10pt;
	overflow: hidden;
	padding: 2px 5px;
	position: fixed;
	z-index: 100;
	max-width: 600px;
	opacity: 0;
	white-space: pre-wrap;
	transition: opacity .4s;
	-moz-transition: opacity .4s;
	-webkit-transition: opacity .4s;
	-o-transition: opacity .4s;
	-ms-transition: opacity .4s;
}

/*.CodeMirror-hover {
	outline: 1px solid grey;
}*/
:root {
  --background-hover-transition-time: .85s;
  --background-active-transition-time: .35s;
}
.App {
    /*text-align: center;*/
     position : absolute;
     height : 100%;
     width : 100%;
     background: black;
}
body {
  overflow : auto !important;

}
.divider {
     width: 100%;
     border: 1px solid lightgray;
}
#headerContainer {
    width:100%;
    display:flex;
    align-items:center;
    padding: 6.5px;
}
#headerContainer > * {
  margin:0 auto;
}
/* Add additional 5 pixels of margin to the loading icon when it is visible */
#headerContainer > p ~ div {
  margin-right: 10px;
}
#headerContainer > p {
}
#splitpane {
    position: relative;
    height : 80%;
    width : 100%;
}
#graph {
    position: relative;
    width : 100%;
    height : 100%;
}
#browser {
    position: relative;
    height : 100%;
    width : 100%;
}
#viewContainer {
    display:flex;
    height:100%;
    width:100%;
    position:absolute;
}
#viewContainer > .SplitPane {
    flex:1 1 !important;
}
#graphContainer[data-vis-mode='VR'], #graphContainer[data-vis-mode='VR'] > div, #graphContainer[data-vis-mode='VR'] > div > div {
  height:100%;
}
#bottomContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height:100%;
  background-color: transparent;
  display:flex;
  align-items:flex-start;
  pointer-events:none; /* So that the transparent container does not consume all the pointer events meant for the force graph */
}
#bottomContainer > * {
  pointer-events:initial; /* So that the container's children can still receive pointer events */
}
#toolbar {
  flex:0 0 auto;
  border-color:rgba(70,70,70,1);
  background-color:rgba(45,45,45, 1);
  border-bottom-width:5px;
}
.Tool {
}
.Tool[data-active-tool="true"] {
  background-color:rgb(15,15,15);
  /* background-color:rgb(220,220,220); */
}
.Tool[data-active-tool="true"] > * {
  /* color:black; */
}
#graphOverlayContainer {
  position:relative;
  flex-grow:1;
  height:100%;
  display:flex;
  align-items:flex-start;
  pointer-events:none;
}
#graphOverlayVerticalContainer {
  flex-grow:1;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
}
#schemaBanner {
    /* background-color: rgb(30, 30, 30); */
    /* border-bottom: 4px solid rgb(50, 50, 50); */
    width: 100%;
    background-color: transparent;
    padding: 10px;
    /* text-align: center; */
    /* text-decoration: underline; */
    font-weight: bold;
    font-style: italic;
    font-size: 18px;
    color: rgb(235, 235, 235);
    display:flex;
    align-items: center;
    pointer-events:none;
}
.LinkExaminer {
  pointer-events:initial;
  margin-left: 10px;
  max-height:400px;
}
.FindTool {
  position: absolute;
  /* width: calc(100% - 16px); */
  width: 400px;
  margin: 8px;
  /* margin-left:0; */
  /* 16px for margin */
  max-height:calc(100% - 16px);
  /* position:absolute;
  right:5px;
  top:5px; */
}
.find-tool-tabs {
  border-bottom:1px solid #dee2e6;
}
.tab-content .tab-content {
  margin-top:25px;
}
.fa-spin {
  /* Pseudo fa-spin class because react-icons doesn't seem to natively support it */
  -webkit-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
#schemaViewToggleButtonContainer {
  flex-grow: 1;
}
#schemaViewToggleButton {
  float: right;
  background-color: black;
  color: #007bff;
  pointer-events:initial;
}
.tableViewPane {
  overflow-y:auto;
  /* padding:15px; */
}
#info {
    position: relative;
    background-color: rgb(39, 40, 34);
    /* We want it to always be at least the same size as the split pane, but we want it to overflow if the JSONTree component is much larger */
    min-height:100%;
    /* bottom: 0px; */
    /* overflow:auto; */
    border-left: 2px solid rgb(166, 226, 46);
}
#info > ul {
    padding:0 1em .5em .5em !important;
    margin:0 !important;
    /* margin:5px !important; */
}
.react-json-view {
    height:100%;
    position: absolute;
    overflow: auto;
 }
.object-viewer-header {
  position:relative;
  width:100%;
  padding:.5em;
  padding-bottom:.25em;
  text-align:center;
  /* border-bottom:2px solid rgb(166, 226, 46); */
 }
.object-viewer-header > h6 {
  color:rgb(166, 226, 46);
  margin-bottom:0;
}
.object-viewer-close-button {
  position:absolute;
  /* Margin is for the 10px padding */
  margin:.5em;
  top:0;
  right:0;
  color:rgb(166, 226, 46);
  font-size:18px;
}
.object-viewer-close-button:hover {
  color:rgb(147, 199, 42);
}
.object-viewer-close-button:active {
  color:rgb(116, 156, 36);
}
.type-chart {
  width: 95%;
  height: 100%;
  max-height: none;
  max-width: none;
}
#typeChartTitle {
  display:flex;
  align-items:center;
}
#swapBar {
  margin-top:2px;
  margin-left:12.5px;
  font-size:24px;
}
.Resizer {
  border-width:0;
  background: transparent;
}
.Resizer.disabled {
  cursor:auto !important;
}

.tableView {
    position : absolute;
    bottom : 0px;
    width : 100%;
    height : 200px;
    border-top: 2px solid lightblue;
 }
.App-logo {
    /*animation: App-logo-spin infinite 20s linear; */
     height: 7vmin;
     pointer-events: none;
    text-align : left;
    position : absolute;
    left : 20px;
    top : 15px;
 }

.App-header {
    background-color: #282c34;
     /* min-height: 8vh; */
     display: flex;
     align-items: center; /* center; */
     justify-content: left; /*center;*/
     color: white;
     font-size: 28px;
     font-style: italic;
     font-weight : bold;
 }
.App-control {
    float : right;
    margin-left : 5px;
    margin-right : 5px;
}
.App-control-toolbar {
  color: rgba(220,220,220,.9);
}
.App-control-toolbar[data-active="true"] {
  color: rgba(160,160,160,.9);
}
.App-control-toolbar.ionic {
  font-size: 22px;
}
.App-control-toolbar.fa {
  font-size: 18px;
}
.answerNavigator {
    width: 95%;
    height: 95%;
    max-width: none!important;
    max-height: 100%;
    height: 100%;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
    /* max-height: 90%; */
    height: 100%;
}
.messageDialog .tab-content {
  margin-top:10px;
}
.messageDialog .tab-pane {
  /* Retain the formatting from the message */
  white-space:pre-wrap;
}
.import-export-modal {
  display:flex !important;
  justify-content:center;
  align-items:center;
}
.import-export-modal-dialog {
  /* height:400px; */
  /* width:500px; */
  max-width:none;
}
.import-export-modal-dialog .modal-body {
  display:flex;
}
.import-export-modal-dialog .modal-body .no-select {
  /* flex:1; */
  background-color:rgb(245,245,245);
  border-radius:5px;
  border:1.5px solid rgb(230,230,230);
  height:auto;
  display:flex;
  flex-direction:column;
  min-height:200px;
}
.import-export-modal-dialog .modal-body .row > * {
  margin:0 5px;
}
.import-export-modal-dialog .modal-body .no-select > * {
  padding:10px;
}
.import-export-modal-dialog .modal-body .no-select > *:not(:last-child) {
  padding-bottom:0;
}
.import-export-modal-dialog .copy-url-container {
  flex-grow: 1;
  margin-top: 10px !important;
}
.import-export-modal-dialog .copy-url-container input {
  background-color: transparent;
  background-color: initial;
}
.import-export-icon-container {
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.import-export-icon-container > * {
  margin-left:7.5px;
  margin-right:7.5px;
}
.import-export-icon-container > svg {
  /* float:left; */
  font-size:28px;
}
.import-export-icon-container > span {
  flex-grow:1;
  font-weight:600;
  font-size:16px;
  display:block;
  text-align:center;
  padding-bottom:10px;
}
.import-options-container, .export-options-container {
  padding:15px !important;
  padding-bottom:0 !important;
  /* 2.5px from horizontal-bar */
  /* padding-top:calc(15px + 2.5px) !important; */
  flex-grow:1;
  display:flex;
  flex-direction:column;
}
.import-options-container > form > div, .export-options-container > form > div {
  /* display:flex; */
  /* align-items:center; */
}
/* .import-options-container > form > div > label, .export-options-container > form > div > label {
  margin-left:0.5rem;
} */
.import-options-container > form, .export-options-container > form {
  flex-grow:1;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
}
.import-options-container > form > *, .export-options-container > form > * {
  margin-bottom:15px;
}
.import-options-container > *:not(:last-child), .export-options-container > *:not(:last-child) {
  margin-bottom:15px;
}
.import-export-icon-container.horizontal-bar::after {
  width:95%;
  left:2.5%;
  top:calc(100% + 2.5px);
  background-color:transparent;
  height:2px;
}
.export-options-container > form > .form-group.form-inline > *:first-child {
  margin-right:.3125rem;
}
.help-modal {
  /* height:60%; */
}
.toolbar-help-modal {
  display:flex !important;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
}
.toolbar-help-modal-dialog {
  height:75%;
  margin:0;
  width:700px;
  max-width:none;
}
.toolbar-help-modal-dialog .modal-body {
  display:flex;
  flex-direction:column;
}
.toolbar-help-modal-dialog .modal-body > .tab-content {
  height:0; /* ?? */
  flex-grow:1; /* ?? the height:0 is required to fix overflow for some reason */
  margin-top:10px;
  display:flex;
}
.toolbar-help-tabs {
  /* flex-grow:1; */
  /* display:flex; */
  /* flex-direction:column; */
  /* height:100%; */
}
.toolbar-help-tab-panel {
  flex-grow:1;
}
.toolbar-help-tab-panel.active {
  display:flex !important;
  flex-grow:1;
}
/* .toolbar-help-tab-panel:not(.react-tabs__tab-panel--selected) {
  display:none;
} */
.toolbar-help-content {
  flex-grow:1;
  margin-left:10px;
}
.toolbar-help-content-body {
  height:100%;
  padding:0;
  display:flex;
  flex-direction:column;
}
.toolbar-help-content-body > .card-text {
  overflow-y:auto;
  padding:.75rem 1.25rem;
}
.toolbar-help-tool-group {
  flex:0 0 12%;
  height:100%;
  overflow-y:auto;
}
.toolbar-help-tool-group > * {
  /* flex:0; */
}
.toolbar-help-tool-group > *:last-child {
  /* No padding allows it to essentially go invisible, meaning that it will not show up if there is any overflow */
  padding:0;
  flex-grow:1 !important;
}
.toolbar-help-tool-button {
  padding-left:0;
  padding-right:0;
  flex-grow:0;
  /* width:33.33%; */
  /* flex: 1 1 0; */
  /* padding-top:0; */
  /* padding-bottom:0; */
  display:flex;
  justify-content:center;
  align-items:center;
  word-break:break-word;
  /* white-space:nowrap; */
  text-align:center;
}
.toolbar-help-tool-button > * {
  padding:0;
}
/* First child is the header of the section */
.section > *:not(:first-child) {
  padding-left:15PX;
}
.section dt {
  /* Tone down the font weight applied to description lists a bit */
  font-weight:500;
}
#helpGrid > * {
  padding-left:7.5px;
  padding-right:7.5px;
}
#helpGrid > *:first-child {
  padding-top:7.5px;
}
#helpGrid > *:last-child {
  padding-bottom:7.5px;
}
#helpGrid > * > * {
  padding:7.5px;
}
#helpGrid > * > * > * {
  height:100%;
}
#spinner {
    position : absolute;
    right : 250px;
    top : 20px;
}
#clearCache {
    float: right;
}
#navModeButton {
}
#runButton {
  height:60%;
    /* position: absolute; */
    /* right: 41px; */
 }
.settingsDialog {
    width: 95%;
    max-width: none!important;
    height: 95%;
    /*max-height: 95%; */
}
.checkbox-container > *:first-child {
  margin-top:5px;
}
#appControlContainer {
  display:flex;
  flex-direction:column;
}
#appControlContainer > * {
  margin:0;
}
#settings {
    /* position: absolute; */
    /* right : 3px; */
    /* top : 11px; */
}
#answerViewer {
  /* position: absolute; */
  /* right : 3px; */
  /* top: 40px; */
}
.Legend {
  border-bottom:1px solid rgb(200,200,200);
}
.Legend[data-closed="true"] {
  border-top:1px solid rgb(200,200,200);
}
.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.splitPane {
    position: relative;
 }
.query-code .CodeMirror-scroll {
    height: 40%;
 }
.query-code .CodeMirror {
    text-align: left!important;
    padding: 2px;
    font-size : 14px;
    font-weight : bold;
    width: 100%;
    height: 50%!important;
    border-color: lightgray 1px solid;
 }
.editor {
  background-color:white;
 }
.editor-vis-control.legend-vis-control {
  z-index:3 !important;
}
.rv-force {
  border: 1px solid gray;
}
.title-edit {
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
  color:#0088cc;
  border-bottom:1px dashed #0088cc;
}
#cachedQueriesModal .query-title {
  min-width:0; /* Need it because flex box changes min-width to auto */
}
#cachedQueriesModal .query-title > input {
  flex-grow:1;
}
/* Elevate it by 1 pixel since the first has a border */
#cachedQueriesModal .query-title > span + span {
  margin-bottom: 1px;
}
/* When the inline editing is active hide the adjacent span because we want 100% width (displays if the query has been edited or not) */
#cachedQueriesModal .query-title > input + span {
  display:none;
}
/* ??? */
.Resizer {
    background: #000;
    opacity: .2;
    z-index: 1;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

 .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

 .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.testing {
  -webkit-transform:translateX(15px);
          transform:translateX(15px);
}
.autocomplete-result-error {
  background-color:var(--danger) !important;
}
.loading-animation::after {
  content: '';
  -webkit-animation:loading-text 2.5s linear infinite;
  animation:loading-text 2.5s linear infinite;
}
.no-select {
  user-select:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -o-user-select:none;
  -ms-user-select:none;
}
.horizontal-bar {
  position:relative;
  padding-bottom:2.5px;
}
.horizontal-bar::after {
  content:'';
  position:absolute;
  bottom:-1.5px;
  left: 0;
  width:100%;
  height:1.5px;
  background-color:rgba(240,240,240,1);
}
.vertical-bar {
  position:relative;
}
.vertical-bar::before {
  content:'';
  position:absolute;
  width:1.25px;
  height:100%;
  left:-1.25px;
  background-color:rgb(215,215,215);
}

/* Embedded style rules */
.App.embedded .react-codemirror2 {
  border: 2px solid #f0f0f0;
}
.App.embedded .CodeMirror {
  padding: 0;
  height: 100% !important;
}
.App.embedded .CodeMirror-scroll {
  height: 100% !important;
  max-height: 30vh;
}

/* Gamma Viewer rules */
body.modal-open > *[role="tooltip"] {
  /* This is attached to the MuiPaper popup/tooltips used in the answer viewer for filtering.
   * Since the Gamma Viewer can appear in a Bootstrap modal, and these popups are positioned absolutely relative to the root,
   * we need to bump up the z-index to ensure they appear above the modal.
   */
  z-index: 2000 !important;
}
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@-webkit-keyframes loading-text {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}

@keyframes loading-text {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}

