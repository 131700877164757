.queries-modal {
  display:flex !important;
  justify-content:center;
  align-items:center;
  width:100%;
  height:100%;
}
.queries-modal-dialog {
  height:75%;
  margin:0;
  width:650px;
  max-height:75%;
  max-width:none;
}
.queries-modal-dialog > .modal-content {
  height:100%;
}
.queries-modal-dialog > .modal-content > .modal-body {
  display:flex;
  overflow-y:auto;
}
.container {
  height:100%;
}
.right-container {
  position:relative;
  display:flex;
  flex-direction:column;
  height:100%;
  /* max-height:100%; */
  /* max-width:75%; */
  /* width:100%; */
  /* flex:0; */
}
.query-list {
  float:left;
  margin-right:16px;
  overflow-y:auto;
  /**
   * Grows to a maximum of 22.5% but may shrink if the largest title is fairly short.
   */
  max-width:22.5%;
  max-height:100%;
}
/**
To extend empty last child for entire height
.query-list > *:nth-last-child(2) {
  border-bottom-width:0.5px;
}
.query-list > *:last-child {
  border-width:0.5px;
  flex-grow:1;
  padding:0;
}
*/
.query-list > button {
  /* Make sure that a really long word won't result in a scroll bar due to overflow */
  word-wrap:break-word;
  text-align:center;
}
.query-title-container {
  display:flex;
  /* justify-content:flex-end; */

  align-items:center;
  /* margin-bottom:14px; */
  /* margin:0px 5px 0 0; */
  padding:7.5px 7.5px;
  background-color:rgb(247,247,247);
  border:1px solid #ddd;
  border-bottom:none;

  /* position:absolute; */
  /* z-index:5000; */
  /* right:25px; */
  /* top:6px; */
}
.query-title {
  margin-right:7.5px;
  flex-grow:1;
  font-size:14px;
  display:flex;
  align-items:center;
  /* font-weight:600; */
}
.query-button-container {
  display:flex;
  align-items:center;
}
.query-button-container > * {
  font-size:14px;
}
.query-button-container > *:not(:last-child) {
  margin-right:8px;
}
.query-modal-code {
  /* flex-grow:1; */
  height:100%;
  /* max-height:100%; */
  display:flex;
  overflow:hidden;
  /* margin-bottom:16px; */
}
.query-modal-code .CodeMirror {
  /* height:100% !important; */
  font-size:13px;
  border:1px solid #ddd;
  flex-grow:1;
  /* overflow:hidden; */
  height:auto;
  width:100%;
  /* max-height:100%; */
}
.query-modal-code .CodeMirror-vscrollbar {
  /* Equivalent of overflow: scroll */
  display:block !important;
}
.query-modal-code .CodeMirror-wrap {
  height:100%;
}
.query-modal-code .CodeMirror-scroll {
  width:100%;
}
.query-modal-code .CodeMirror-lines {
  display:flex;
}
