.LinkExaminer {
  background-color:rgba(0,0,0,.4); /* replace with rgb(20,20,30) to remove alpha layer */
  color:rgba(220,220,220,1);
  overflow-y:auto;
  border: 2px solid rgba(125,125,125,1);
}
.link-examiner-header {
  /* border-bottom: 1.5px solid rgba(240,240,240,1); */
  display:flex;
  position:relative;
  justify-content:space-between;
  align-items:center;
  padding-bottom:2.5px;
  margin:2.5px 5px;
}
.link-examiner-header-text {
  display:inline-block;
  padding-bottom:2.5px;
  font-size:16px;
  font-weight:600;
}
.link-examiner-close-button {
  margin-left:20px;
}
.link-examiner-close-button:hover {
  color:rgb(200,200,200);
}
.link-examiner-close-button:active {
  color:rgb(175,175,175);
}
.link-examiner-header-info {
  font-size:12px;
  margin: 7.5px 0 7.5px 0;
  font-weight:bold;
  position:relative;
}
.header-node {
  margin:5px;
}
.header-node > *:first-child {
  margin-right:5px;
}
.link-label {
  font-size:12px;
  padding:1px;
  margin:5px;
}
.link-label > * {
  margin:2px;
}
.link-label-text {
  cursor:pointer;
  /* color:#2b9cff; */
}
